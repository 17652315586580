import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <h1></h1>
    <ProjectCard title="The Laceless Life" link="https://github.com/nuridavid/laceless-life" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Full stack e-commerce application using React, Node, Express, SCSS, and CSS.
  Hosted on Heroku.
    </ProjectCard>
    <ProjectCard title="Instock" link="https://github.com/nuridavid/instock" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Full stack application using React, Node, Express, SCSS, and CSS
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      