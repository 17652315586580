import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About me`}</h1>
    <p>{`I am a front-end developer who fell in love with programming and the analytical thinking associated with it. I grew up thinking very logically and challenged myself to problem-solve with every opportunity I had. I have always been fascinated by technology and have continuously learned new ways to use it.`}</p>
    <p>{`When I enrolled in a coding class I was finally challenged by technology. This ignited my passion for programming- the satisfaction I got from fixing and refactoring a piece of code was the best feeling I’ve ever experienced!`}</p>
    <p>{`I’ve always had a yearning to keep learning and I can’t think of a more apropos and fulfilling field to do that than web development! I can’t wait to continue my journey of continuous and consistent learning while also doing what I love.`}</p>
    <p>{`When I am not building sites, you can find me watching sports games, jogging, and spending time with my loved ones.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      